<template>
  <body-layout>
    <div class="actions flex" slot="actions">
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        icon="delete"
        v-if="checkPermission('statistics:rating:dele')&&selectedRowKeys.length"
        >删除</a-button
      >
      <a-button v-if="isEdit"  class="mr-3" @click="batchCencal">取消</a-button>
      <a-button type="primary" v-if="checkPermission('statistics:rating:batchEdit')" class="mr-3" @click="batchEdit">{{
        isEdit ? '保存' : '编辑'
      }}</a-button>
      <a-button
        type="primary"
        v-if="checkPermission('statistics:rating:rerating')"
        :loading="btnLoading"
        class="mr-3"
        @click="manualRate"
        >重新评级</a-button
      >
      <!--       <a-button type="primary" v-has="'statistics:rating:post'" @click="sendEmail" v-if="allowSendEmailShow"
        >发送</a-button
      > -->
      <a-button
        v-has="'statistics:rating:post'"
        :disabled="
          !selectedRowKeys.length ||
            (selectionRows.some(item => item.status != 'HR_CONFIRM') &&
              userInfo.roles.some(item => item.roleCode == 'hr'))
        "
        v-if="selectedRowKeys.length"
        type="primary"
        @click="confirmRating"
        >确认</a-button
      >
    </div>
    <div class="table-page-search-wrapper" v-has="'statistics:rating:search'" slot="search">
      <a-form layout="inline" @keyup.enter.native="searchQueryForm">
        <a-row type="flex" :gutter="16">
          <a-col>
            <a-select
              mode="multiple"
              :showArrow="false"
              :not-found-content="null"
              :filter-option="false"
              placeholder="请输入人员昵称"
              option-filter-prop="label"
              v-model="userIds"
              style="width: 200px"
              @change="searchQueryForm"
              @search="userSearch"
            >
              <a-select-option v-for="user in searchUsers" :label="user.userName" :key="user.userId">
                {{ user.userName }}
              </a-select-option>
            </a-select>
          </a-col>
          <!--           <a-col>
            <a-month-picker v-model="month" format="YYYY-MM" placeholder="选择月份" />
          </a-col> -->
          <a-col>
            <a-button type="primary" icon="search" @click="searchQueryForm">搜索</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <!-- <a-table
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :dataSource="dataSource"
      :customRow="clickRow"
      :pagination="false"
      :scroll="{ x: 1000, y: 500 }"
      :loading="loading"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      @change="handleTableChange"
    >
      <template slot="status" slot-scope="text, record">
        {{ getStatusText(record) }}
      </template>
      <template slot="level" slot-scope="text, record">
        <a-input v-if="isEdit" v-model="record.level" />
        <span v-else>{{ record.level }}</span>
      </template>
    </a-table> -->
    <!--     <v-table
      ref="newTable"
      :selectedRowKeysprop="selectedRowKeys"
      :url="url"
      :queryParamProp="queryParam"
      @openModal="openModal"
      :tablePage="tablePage"
      :isEdit="isEdit"
      tableType="ratingTable"
      :columns="columns"
      @setRowkeys="setRowkeys"
      @setTablePage="setTablePage"
    /> -->
    <!--     <div style="display:flex;justify-content: end;z-index:99999">
      <a-pagination
        show-quick-jumper
        show-size-changer
        v-model="tablePage.currentPage"
        :page-size-options="['10', '20', '30', '40', '50']"
        :default-current="1"
        :defaultPageSize="tablePage.pageSize"
        :total="tablePage.totalResult"
        @showSizeChange="showSizeChange"
        @change="handlePageChange"
      />
    </div> -->
    <rating-table
      :userInfo="userInfo"
      :isAdmin="isAdmin"
      :isEdit="isEdit"
      :queryParamProp="queryParam"
      @open="open"
      @setRowkeys="setRowkeys"
      ref="table"
    />
    <rating-modal
      :userInfo="userInfo"
      :open="drawerShow"
      :cur-row="curRow"
      :isForm="isNewForm"
      @closed="drawerClosed"
    ></rating-modal>
  </body-layout>
</template>

<script>
import { checkPermission } from '@/utils/hasPermission'
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import vTable from '@/components/v-table/vTable'
import RatingModal from './modules/rating-modal.vue'
import RatingTable from './modules/rating-table.vue'
import { getAction, putAction, postAction } from '@/api/manage'
import Vue from 'vue'
import { USER_INFO } from '@/store/mutation-types'
import { simpleDebounce } from '@/utils/util'
export default {
  mixins: [JeecgListMixin],
  components: {
    RatingModal,
    vTable,
    RatingTable
  },
  data() {
    return {
      columns: [
        {
          title: '月份',
          dataIndex: 'month',
          width: 150,
          checked: true,
          isNum: false
        },
        {
          title: '部门',
          dataIndex: 'departName',
          checked: true,
          isNum: false
        },
        {
          title: '签约类型',
          dataIndex: 'contractType',
          checked: true,
          isNum: false
        },
        {
          title: '职位',
          dataIndex: 'positionName',
          checked: true,
          isNum: false
        },
        {
          title: '人员',
          dataIndex: 'nickName',
          checked: true,
          isNum: false
        },
        {
          title: '合作时长',
          dataIndex: 'duration',
          scopedSlots: { customRender: 'duration' },
          checked: true,
          isNum: true
        },
        {
          title: '任务数量',
          dataIndex: 'taskCount',
          scopedSlots: { customRender: 'taskCount' },
          checked: true,
          isNum: true
        },
        {
          title: '平均分',
          dataIndex: 'averageScore',
          scopedSlots: { customRender: 'averageScore' },
          checked: true,
          isNum: true
        },
        {
          title: '等级',
          scopedSlots: { customRender: 'level' },
          dataIndex: 'level',
          checked: true,
          isNum: false
        },
        {
          title: '状态',
          scopedSlots: { customRender: 'status' },
          dataIndex: 'status',
          checked: true,
          isNum: false
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'action' },
          dataIndex: 'action',
          checked: true,
          isNum: false
        }
      ],
      positionList: [],
      btnLoading: false,
      tablePage: {
        currentPage: 1,
        pageSize: 50,
        totalResult: 0
      },
      translateUsers: [],
      userIds: [],
      monthFormat: 'YYYY-MM',
      month: '',
      isEdit: false,
      isAdmin: false,
      searchUsers: [],
      userInfo: {
        roles: []
      }
    }
  },
  methods: {
    checkPermission(type) {
      return checkPermission(type)
    },
    batchDel: function() {
      let data = []
      let that = this
      if (!this.selectionRows.length) {
        this.$message.warning('请选择至少一条数据！')
        return
      }
      this.selectionRows?.forEach(item => {
        data.push(JSON.parse(JSON.stringify(item)))
      })
      if (data.length) {
        data.forEach(item => {
          item.month = item.month + '-01'
        })
      }
      this.$confirm({
        title: '确认删除',
        content: '是否删除选中数据?',
        onOk: function() {
          that.loading = true
          postAction('/rating/deleteBatch', data)
            .then(res => {
              if (res.success) {
                that.$message.success(res.message || res.msg ? '删除成功' : '')
                that.$refs.table.getTableData()
              } else {
                that.$message.warning(res.message || res.msg)
              }
            })
            .finally(() => {
              that.loading = false
            })
        }
      })
    },
    confirmRating: simpleDebounce(async function() {
      this.confirmRatingSim()
    }, 500),
    confirmRatingSim() {
      postAction('/rating/batchConfirm', this.selectedRowKeys).then(res => {
        if (res.code == 200) {
          this.$message.success('确认完毕')
          this.$refs.table.getTableData()
        }
      })
    },
    userSearch(value) {
      if (value) {
        this.searchUsers = this.translateUsers.filter(item => item.userName.indexOf(value) > -1)
      } else {
        this.searchUsers = []
      }
    },
    open(row) {
      this.openDetail(row)
    },
    showSizeChange(e, num) {
      this.tablePage.pageSize = num
      this.$refs.newTable.loadData()
    },
    setTablePage(total) {
      if (total) {
        this.tablePage.totalResult = total - 0
      } else {
        this.tablePage.totalResult = 0
      }
    },
    handlePageChange() {
      this.$refs.newTable.loadData()
    },
    batchCencal() {
      this.isEdit = false
      this.$refs.table.getTableData()
    },
    openModal(record) {
      this.openDetail(record)
    },
    setRowkeys(records) {
      records = records.filter(item => !item.hasChild)
      this.selectedRowKeys = records.map(item => {
        return item.id
      })
      this.selectionRows = records
    },
    async batchEdit() {
      if (this.isEdit) {
        let data = []
        this.$refs.table.tableData?.forEach(item => {
          item.children?.forEach(i => {
            i.children?.forEach(row => {
              data.push(JSON.parse(JSON.stringify(row)))
            })
          })
        })
        if (data.length) {
          data.forEach(item => {
            item.month = item.month + '-01'
          })
          const res = await postAction('/rating/updateBatch', data)
          if (res.code != 500) {
            this.$message.success('保存成功')
            this.$refs.table.getTableData()
          } else {
            this.$message.error(res.msg)
          }
        }
      }
      this.isEdit = !this.isEdit
    },
    searchQueryForm() {
      if (this.month && this.month.format) {
        this.queryParam.month = this.month.format(this.monthFormat)
      } else {
        this.queryParam.month = ''
      }
      this.queryParam.userIds = this.userIds
      /* this.searchQuery() */
      this.$refs.table.getTableData()
    },
    getAllPosition() {
      getAction('/sys/position/list').then(res => {
        if (res.success) {
          this.positionList = res.data.records
          this.positionList.forEach(one => {
            if (one.name == '翻译') {
              this.getAllTranslateUsers(one.id)
            }
          })
        }
      })
    },
    getAllTranslateUsers(postId) {
      getAction('/sys/sysDepart/userListToSelect', {
        positionId: postId
      }).then(res => {
        this.translateUsers = Object.assign([], res.data)
      })
    },

    getStatusText(record) {
      switch (record.status) {
        case 1:
          return 'HR确认' + (record.rejectType == 1 ? '(BOSS驳回)' : record.rejectType == 2 ? '(人员驳回)' : '')
        case 2:
          return 'BOSS确认' + (record.rejectType == 1 ? '(BOSS驳回)' : record.rejectType == 2 ? '(人员驳回)' : '')
        case 3:
          return '人员确认' + (record.rejectType == 1 ? '(BOSS驳回)' : record.rejectType == 2 ? '(人员驳回)' : '')
        case 4:
          return '人员已确认'
      }
    },

    manualRate: simpleDebounce(async function() {
      this.manualRateSim()
    }, 500),
    manualRateSim() {
      this.btnLoading = true
      let ids = this.selectedRowKeys.join(',')
      putAction('/rating/manual_rate?ids=' + ids).then(res => {
        if (res.code == 200) {
          this.btnLoading = false
          this.$refs.table.getTableData()
          this.$message.success('评级成功')
        } else {
          this.btnLoading = false
          this.$message.error(res.msg || res.message)
        }
      })
    },

    sendEmail: simpleDebounce(async function() {
      this.sendEmailSim()
    }, 500),
    sendEmailSim() {
      this.$refs.table.reExpend()
      postAction('/rating/send_email').then(({ success, msg }) => {
        if (success) {
          this.$message.success('发送成功')
          this.$refs.table.getTableData()
        } else {
          this.$message.error(msg)
        }
      })
    }
  },
  created() {
    this.getAllPosition()
    this.userInfo = Vue.ls.get(USER_INFO)
    this.isAdmin = false
    if (this.userInfo.roles) {
      this.userInfo.roles.forEach(one => {
        if (one.roleCode == 'admin' || one.roleCode == 'boss' || one.roleCode == 'tongchou' || one.roleCode == 'hr') {
          this.isAdmin = true
        }
      })
    }
  },

  computed: {
    allowSendEmailShow() {
      let allow = true
      if (this.dataSource) {
        this.dataSource.forEach(one => {
          if (one.firstStagePass) {
            allow = false
          }
        })
      }
      return allow
    }
  }
}
</script>

<style lang="less" scoped>
.red {
  color: red;
}
/deep/ .ant-calendar-picker-input::-webkit-input-placeholder {
  font-size: 14px !important;
}
/deep/ .ant-calendar-picker-input::-moz-input-placeholder {
  font-size: 14px !important;
}

/deep/ .ant-calendar-picker-input::-ms-input-placeholder {
  font-size: 14px !important ;
}
</style>
